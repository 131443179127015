import { environment } from "src/environments/environment.prod";

const endpoints = environment.apiUrl;
const link = (name: any) => {
  return endpoints + name;
};
export const URL = {
  // Employe
  GET_EMP_BY_PID: link(`employee/getEmployeeByPid/`),
  GET_EMP_BY_NIF: link(`employee/getEmployeeByNif/`),
  GET_EMP_BY_ID: link(`employee/getEmployeeById/`),
  COUNT_EMP: link(`employee/getCountEmployee`),
  GET_ALL_EMPLOYEE: link(`employee/getAllEmployee`),
  GET_ALL_EMPLOYEE_HAVE_NOT_CONTRAT_FOR_CURRENT_FISCAL_YEAR: link(
    `employee/getAllEmployeeHaveNotContratForCurrentFiscalYear`
  ),
  GET_EMPLOYEE_HAVE_NOT_CONTRAT_FOR_CURRENT_FISCAL_YEAR_BY_PID: link(
    `employee/getEmployeeHaveNotContratForCurrentFiscalYearByPid/`
  ),
  GET_ALL_EMPLOYEE_NOT_USER: link(`employee/getAllEmployeeNotUser`),
  GET_ALL_EMPLOYEE_DONT_HAVE_DOC: link(
    `employee/getAllEmployeDontHaveDocuments`
  ),
  UPDATE_EMPLOYEE: link(`employee/updateEmploye`),
  CREATE_EMPLOYEE: link(`employee/create`),
  CREATE_EMP_VIA_EXCEL: link(`employee/createEmployeViaExcel`),
  CREATE_EMP_BY_CRITERIA: link(`employee/getEmployeeByCriteriaSpecification`),
  GET_ALL_EMPLOYEE_BY_CRITERIA: link(`employee/getAllEmployeeByCriteria`),

  // Citizen
  GET_CITIZEN: link(`citizen/`),

  // Image
  GET_IMG_BY_IDEMP: link(`image/getImageByIdEmploye/`),

  // Categories
  GET_ALL_CAT_PARENTS: link(`categorieJob/getAllCategorieParents`),

  // Role
  REMOVE_ROLE_TO_USER: link(`role/removeRoleToUser/`),
  GET_ALL_ROLE_WITH_PERMS: link(`role/getAllWithPermissions`),
  ADD_ROLE_TO_USER: link(`role/addRoleToUser/`),
  ADD_PRIVILEGE_TO_ROLE: link(`role/getAllEmployee/`),
  CREATE_ROLE: link(`role/create`),

  // User
  LOCK_OR_UNLOCK_USER: link(`user/lockOrUnlockUser/`),
  COUNT_USER: link(`user/getCountUsers`),
  GET_ALL_USER: link(`user/getAllUser`),
  UPDATE_USER: link(`user/updateUser`),
  UPDATE_PASSWORD: link(`user/updatePassword`),
  CREATE_USER: link(`user/create`),

  // Document
  UPLOAD_DOC_EMP: link(`documentsEmploye/addDocuments/`),
  GET_ALL_DOC_EMP: link(`documentsEmploye/getAllDocumentsForEmploye/`),

  // Signin
  SIGNIN: link(`authentification/signin`),

  // ALl enum
  GET_ALL_ENUM: link(`enum/getAllEnum`),

  // Contrat Template
  ADD_CONTRAT: link(`contrat/add`),
  ADD_ARTICLE: link(`contrat/addArticle`),
  GET_TEMP_CONTRAT_BY_ID: link(`contrat/getTemplateContratById/`),
  GET_ALL_CONTRAT_TEMP: link(`contrat/getAllTemplateContrat`),

  // Config
  GET_CONFIG: link(`config/getConfig`),
  UPDATE_CONFIG: link(`config/update`),
  GET_TEMPAPLECONTRAT: link(`contrat/getTemplateContratWithNameAndId`),
  CREATE_CONTRAT_EMP: link(`contrat/generateContratForOneEmploye/`),
  GET_ALL_CONTRAT_OF_THIS_YEAR: link(`contrat/getAllContratOfThisYear`),
  GET_ALL_CONTRAT_OF_THIS_YEAR_BY_CRITERIA: link(
    `contrat/getAllContratOfThisYearByCriteria`
  ),

  GET_CONTRAT_OF_THIS_YEAR_BY_PID: link(`contrat/getContratOfThisYearByPid/`),
  UPDATE_SALAIRE_EMPLOYEE: link(`employee/changementSalaireEmploye`),
  GET_CONTRAT_EMP_BY_ID: link(`contrat/getEmpContratById/`),
  GET_ALL_EMPLOYEE_NOT_AFFECTED: link(
    `employee/getAllEmployeeNotAffectWithOffice`
  ),
  GET_EMP_WSNV: link(`employee/getEmployeeWithSalaryNotValid`),
  GET_DIRECTOR: link(`employee/getCurrentDirector`),
  SEARCH_EMP: link(`employee/getEmployeeByCriteria/`),
  BECOME_DIRECTOR: link(`employee/becomeDirector/`),
};
