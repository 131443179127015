<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo" routerLink='/'>
                            <img class="img-fluid for-light" src="assets/images/logo/oni-logo-description.png" alt="looginpage" style="width: 500px; height: 195px;">
                            <img class="img-fluid for-dark" src="assets/images/logo/oni-logo-description.png" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main">

                        <form class="theme-form" [formGroup]="loginForm">
                            <h4>Connectez-vous</h4>
                            <p>Entrez votre nom d'utilisateur et mot de passe pour se connecter </p>
                            <div class="form-group">
                                <label class="col-form-label">Nom d'utilisateur </label>
                                <input class="form-control" type="text" required="" placeholder="1145485896" formControlName="email">
                                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">
                                    Vous devez ajouter votre nom d'utilisateur
                                </div>
                                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">
                                    nom d'utilisateur invalide
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Mot de passe</label>
                                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                                    Vous devez ajouter votre mot de passe
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <button class="btn btn-primary btn-block" [class.loader--text]="auth.showLoader" [disabled]="!loginForm.valid || auth.showLoader" (click)="login()" type="button"><span>{{ auth.showLoader ? '' : 'Se connecter' }}</span>
                                </button>
                            </div>

                            <p class="mt-4 mb-0">Vous avez oublié votre mot de passe?
                                <a [routerLink]="'/auth/fpass'" class="ml-2">Réinitialiser mot de passe</a>
                            </p>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>