import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';



@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public showLoader: boolean = false;
  private currentUserSubject: BehaviorSubject<any>;
  public user: any;
  public currentUser: Observable<any>;

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private http: HttpClient,
    private cookieService: CookieService) {
      this.user = localStorage.getItem('currentUser');
      let data =JSON.parse(this.user);
      this.currentUserSubject = new BehaviorSubject<any>(data);
      this.currentUser = this.currentUserSubject.asObservable();
  }

  ngOnInit(): void { }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  // tslint:disable-next-line:typedef
  register(creds:any) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/register`, creds)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}authentification/signin`, {
        username,
        password
      })
      .pipe(
        map(rep => {
          if (!rep.crash) {
              this.setUserData(rep.data);
          }
          return rep;
        })
      );
  }

  // tslint:disable-next-line:typedef
  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
   setUserData(data: any){
     localStorage.setItem('currentUser', JSON.stringify(data));
     this.currentUserSubject.next(data);
   }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null ) ? true : false;
  }

  public toastError(msg: string) {
    this.toster.error(msg);
  }

   public toastSuccess(msg: string) {
    this.toster.success(msg);
   }


}