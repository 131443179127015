import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  
  constructor(private authenticationService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      const token = `Bearer ${ currentUser.token}`;
      // console.log(token);
      const headers = new HttpHeaders({
        Accept: 'application/json',
        Authorization: token 
       // 'Content-Type':'application/json',
      });
      request = request.clone({ headers });
    }

    return next.handle(request);
  }
}
