let prod = true;
let local = "localhost";
//let local = "192.168.150.3";
// let local = "197.168.1.42";
//let local = "172.23.0.3";
let server = "rhmana";
let port = "8989";
export const getUrl = (name: any) =>
  prod ? `https://${name}.herokuapp.com/api/`: "http://" + name + ":" + port + "/api/";

let url = () => {
  return prod ? server : local;
};
export const environment = {
  production: prod,
  node: false,
  apiUrl: getUrl(url()),
  local: local,
  server: server,
};
