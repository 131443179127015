import { Injectable } from "@angular/core";
import htmlToPdfmake from "html-to-pdfmake";
import _ from "lodash";
import moment from "moment";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import writtenNumber from "written-number";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class PDFService {
  window: any;
  constructor() {
    // var { JSDOM } = jsdom;
    // this.window = new JSDOM("").window;
  }
  getParagraph(paragraph: string, employee: any, config: any): string {
    let employeeFullName = `${this.capitalizeLetters(
      employee.first_name.toLowerCase()
    )} ${employee.last_name.toUpperCase()}`;

    let employeeAddress = `${this.capitalizeLetters(
      employee.employee.commune_res.toLowerCase()
    )}`;

    let directorFullName = `${this.capitalizeLetters(
      employee.director.employee.first_name.toLowerCase()
    )} ${employee.director.employee.last_name.toUpperCase()}`;

    let dir = employee.director.employee;

    paragraph = paragraph.replace("#{director}", directorFullName);

    paragraph = paragraph.replace("#{nin_dir}", dir.pid);
    paragraph = paragraph.replace("#{nif_dir}", dir.nif);
    paragraph = paragraph.replace("#{position_emp}", `« ${employee.poste} »`);
    paragraph = paragraph.replace("#{commune_res}", employeeAddress);

    paragraph = paragraph.replace("#{address_emp}", employeeAddress);

    //
    paragraph = paragraph.replace(/#{etab_name}/gi, config.etab_name);

    paragraph = paragraph.replace(/#{etab_sigle}/gi, config.sigle);

    paragraph = paragraph.replace(
      "#{nif_etab}",
      config.nif_etab ? config.nif_etab : "###-###-###-#"
    );
    //
    paragraph = paragraph.replace("#{fullname_emp}", employeeFullName);
    if (employee.employee.nif) {
      paragraph = paragraph.replace("#{nif_emp}", employee.employee.nif);
    } else {
      paragraph = paragraph.replace("#{nif_emp}", "<i>Nif non mentionné</i>");
    }

    paragraph = paragraph.replace("#{nin_emp}", employee.employee.pid);

    paragraph = paragraph.replace(
      "#{salaire_emp}",
      `${this.numberToWord(employee.salaire)}  (${this.numberToCurrency(
        employee.salaire
      )})`
    );
    paragraph = paragraph.replace(
      "#{salaire_emp_annuel}",
      `${this.getPeriodicSalary(
        employee.salaire,
        employee.dateDebut,
        employee.dateEnd
      )}`
    );

    paragraph = paragraph.replace(
      /#{date_debut}/gi,
      this.getFrenchDateFormat(employee.dateDebut)
    );
    paragraph = paragraph.replace(
      /#{date_fin}/gi,
      this.getFrenchDateFormat(employee.dateEnd)
    );
    paragraph = paragraph.replace(
      "#{duree_contrat}",
      this.getNumberYearBetweenDate(employee.dateDebut, employee.dateEnd)
    );
    return paragraph;
  }

  articleParagraphOrTitle(articleOrParagraphs, attr, style) {
    return articleOrParagraphs?.map((artPar) => {
      return {
        text: [{ text: htmlToPdfmake(artPar[attr]), style }],
      };
    });
  }

  generatePDF(contrat: any, employee: any, config, type): void {
    pdfMake.fonts = {
      Times: {
        normal: "times.ttf",
        bold: "timesbd.ttf",
        bolditalics: "timesbi.ttf",
        italics: "timesi.ttf",
      },
    };

    const articleParagraphs = contrat?.tarticles.map((article) => {
      const title = this.removeHTMLTag(article.titre);
      const articlePDF = htmlToPdfmake(title);

      console.log("titre", title);

      const addPageBreak =
        _.includes(article.titre, "Article 5.-") ||
        _.includes(article.titre, "Article 12.-")
          ? { pageBreak: "before" }
          : undefined;

      // let addPageBreak = undefined;
      // if (_.includes(article.titre, "Article 5.-")) {
      //   addPageBreak = { pageBreak: "before", marginTop: 30 };
      // }

      // if (_.includes(article.titre, "Article 11.-")) {
      //   addPageBreak = { pageBreak: "before" };
      // }

      const articleTitle = articlePDF
        .filter((data) => data["text"] !== "")
        .map((d) => {
          return {
            ...d,
            style: "articleTitle",
            margin: 0,
          };
        });

      return {
        ...addPageBreak,
        stack: [
          [...articleTitle],
          ,
          ...article?.tpararticles.map(({ paragraphe }) => {
            const data = this.getParagraph(paragraphe, employee, config);

            const paragraph = this.removeHTMLTag(data);
            const paragraphPDF = htmlToPdfmake(paragraph);

            const hasULOrOL =
              _.includes(paragraph, "<ul>") || _.includes(paragraph, "<ol>");

            const ulAndOLOptions = _.includes(paragraph, "<ul>")
              ? {
                  type: "circle",
                  separator: "-",
                  style: { margin: 0 },
                  margin: 0,
                  marginBottom: 0,
                }
              : {
                  type: "lower-alpha",
                  separator: ")",
                  style: { margin: 0 },
                  margin: 0,
                  marginBottom: 0,
                };

            const removeEmptyString = paragraphPDF.filter(
              (data) => data["text"] !== " "
            );

            const addOptions = removeEmptyString.map((data) => {
              if (data["nodeName"]) {
                return {
                  ...data,
                  ...ulAndOLOptions,
                };
              }
              return { ...data };
            });

            return hasULOrOL
              ? [...addOptions, "\n\n"]
              : {
                  text: [
                    {
                      text: [...removeEmptyString, "\n\n"],
                    },
                  ],
                };
          }),
        ],
      };
    });

    let employeeFullName = `${this.capitalizeLetters(
      employee.first_name.toLowerCase()
    )} ${employee.last_name.toUpperCase()}`;

    let directorFullName = `${this.capitalizeLetters(
      employee.director.employee.first_name.toLowerCase()
    )} ${employee.director.employee.last_name.toUpperCase()}`;

    console.log("articleParagraphs", articleParagraphs);

    const docDefinition = {
      pageSize: "LETTER",
      pageOrientation: "portrait",
      pageMargins: [55, 70, 55, 30],

      info: {
        title: "Contrat 2022-2023 " + employeeFullName + ".pdf",
        author: "",
        subject: "",
        keywords: "",
      },

      footer: function (currentPage, _) {
        return {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: " " + currentPage.toString(),
                  alignment: "right",
                  style: "normalText",
                  margin: [0, 10, 25, 0],
                  aligment: "left",
                },
              ],
            ],
          },
          layout: "noBorders",
        };
      },
      content: [
        { text: contrat?.titre, style: "contractTitle" },
        ...articleParagraphs,
        {
          absolutePosition: { x: 40, y: 700 },
          style: "tableStyles",
          layout: "noBorders",
          table: {
            widths: ["*", "*"],
            heights: [20],
            headerRows: 1,

            body: [
              [
                {
                  text: "________________________________",
                  style: "tableHeader",
                },
                {
                  text: "________________________________",
                  style: "tableHeader",
                },
              ],
              [
                `Contractant(e) \n ${employeeFullName}`,
                `Employeur \n ${directorFullName}`,
              ],
              // [employeeFullName, directorFullName],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 12,
        font: "Times",
        lineHeight: 1,
        alignment: "justify",
      },

      styles: {
        "html-p": {
          margin: [0, 2, 0, 8],
        },
        "html-li": {
          marginLeft: 35,
        },
        "html-ul": {
          marginBottom: 0,
        },
        "html-ol": {
          marginBottom: 0,
        },

        tableStyles: {
          alignment: "center",
          bold: true,
          margin: 0,
        },

        tableHeader: {
          bold: false,
          margin: 0,
        },
        contractTitle: {
          bold: true,
          alignment: "center",
          decoration: "underline",
          margin: [0, 0, 0, 25],
        },
        articleTitle: {
          margin: [0, 0, 0, 8],
        },
      },
    };

    // console.log("BUttonTYpe", type);

    switch (type) {
      case "print":
        pdfMake.createPdf(docDefinition).print();

        break;
      case "download":
        pdfMake
          .createPdf(docDefinition)
          .download(
            "Contrat 2022-2023 " + employeeFullName + ".pdf",
            function () {
              // alert("your pdf is done");
            }
          );
        break;
      default:
        pdfMake.createPdf(docDefinition).open();
        break;
    }
  }

  removeHTMLTag(str: string) {
    const REGEX = /<\/?(html|head|title|body)\b[^<>]*>/g;
    return str.replace(REGEX, "").trim();
  }

  removeSpace(str) {
    return str.replaceAll("&nbsp;", "").trim();
  }

  getPeriodicSalary(salary: number, dateDebut, dateEnd): string {
    const period: number = this.getMonthDiffBetweenDate(dateDebut, dateEnd);
    const salaryTotal: number = period * salary;
    return `${this.numberToWord(salaryTotal)}  (${this.numberToCurrency(
      salaryTotal
    )})`;
  }

  numberToWord(num: number): string {
    return writtenNumber(num, { lang: "fr" });
  }

  numberToCurrency(amount: number): any {
    const fixedAmount: string = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "HTG",
    })
      .format(amount)
      .toString();
    return fixedAmount.replace("HTG", "");
  }

  capitalizeLetters(mySentence: string) {
    const words = mySentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  }

  getNumberYearBetweenDate(startDate: any, endDate: any) {
    const month = this.getMonthDiffBetweenDate(startDate, endDate);
    return month ? `${this.numberToWord(+month)} (${month}) mois` : "";
  }

  getFrenchDateFormat(date: any) {
    const dateFormat = moment(date).locale("fr").format("LL");
    const dateSplit = dateFormat.split(" ");

    const day =
      dateSplit[0] == "1"
        ? `<span>${dateSplit[0]}<sup>er</sup></span>`
        : dateSplit[0];
    return `${day} ${this.capitalizeLetters(dateSplit[1])} ${dateSplit[2]}`;
  }

  getMonthDiffBetweenDate(startDate: any, endDate: any): number {
    return (
      Math.abs(
        moment(endDate, "YYYY-MM-DD")
          .startOf("month")
          .diff(moment(startDate, "YYYY-MM-DD").startOf("day"), "months")
      ) + 1
    );
  }
}
