import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { AppService } from "src/app/shared/services/app.service";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  public show: boolean = true;
  public loginForm: FormGroup;
  public errorMessage: any;
  public returnUrl: any;

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppService
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
    });
    let user = this.auth.currentUserValue;
    if (user) {
      this.router.navigate(["/dashboard/home"]);
    }
  }

  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || "/dashboard/home";
  }

  showPassword() {
    this.show = !this.show;
  }

  login() {
    this.auth
      .login(this.loginForm.value["email"], this.loginForm.value["password"])
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (!data.crash) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.auth.toastError(data.message);
          }
        },
        (error) => {
          this.auth.toastError(error);
        }
      );
  }
}
