<div class="media profile-media">
    <img class="b-r-10" src="{{url}}image/getImageByIdEmploye/{{user.employeDto.id}}" width="37" height="37" alt="">
    <div class="media-body"><span>{{user.employeDto.last_name}} {{user.employeDto.first_name}}</span>
        <p class="mb-0 font-roboto">
            <span *ngFor="let item of user.listroles"> {{item.name}} </span>
            <i class="middle fa fa-angle-down"></i>
        </p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li>
        <a routerLink="/dashboard/auth/profil">
            <app-feather-icons [icon]="'user'"></app-feather-icons><span>Compte </span>
        </a>
    </li>
    <li>
        <a routerLink="/dashboard/auth/change-password">
            <app-feather-icons [icon]="'lock'">
            </app-feather-icons><span>Confidentialité</span>
        </a>
    </li>
    <li>
        <a routerLink="/auth/logout">
            <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Déconnexion</span>
        </a>
    </li>
</ul>