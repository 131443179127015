import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {  map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { URL } from '../EUrl';

import { createRequestOption, SearchWithPagination } from 'src/app/shared/helpers/request-util';

type EntityArrayResponseType = HttpResponse<any[]>;
@Injectable({
  providedIn: "root",
})
export class AppService {

  

  public url = URL;
  showToast(data: any) {
    (data.crash) ? this.toastError(data.message) : this.toastSuccess(data.message);
  }
 
  error(data: any) {
    console.log(data);
      if (data.error.message) 
      this.toastError(data.error.message)

      if (data.error) 
      this.toastError(data.error)
  
  }
 
  constructor(private http: HttpClient, private toster: ToastrService) {
  }


 search(req: SearchWithPagination,url: string): Observable<EntityArrayResponseType> {
     const options = createRequestOption(req);
     return this.http.get<any>(url, { params: options, observe: 'response' }).pipe(map((res: EntityArrayResponseType) => res));
   }


  public URL() { 
    return this.url;
  }
  public toastError(msg: string) {
    this.toster.error(msg);
  }

   public toastSuccess(msg: string) {
    this.toster.success(msg);
   }


  getData(url: string) {
    return this.http.get<any>(url).pipe(
      map((rep) => {
        return rep;
      }
      )
    );
  }

  getDataJson(url: string) {
    return this.http.get<any>(url).pipe(
      map((rep) => {
        return [rep.crash,rep.message,rep.data.json()];
      })
    );
  }

  delData(url: string) {
    return this.http.delete<any>(url).pipe(
      map((rep) => {
        return rep;
      })
    );
  }

  setData(url: string, data: any) {
    return this.http.post<any>(url, data).pipe(
      map((rep) => {
        return rep;
      })
    );
  }

   setData2(url: string, data: any): Promise<any> {
    return this.http.post<any>(url, data).pipe(
      map((rep) => {
        return rep;
      })
    ).toPromise();
  }

  editData(url: string, data: any) {
    return this.http.patch<any>(url, data).pipe(
      map((rep) => {
        return rep;
      })
    );
  }

  upload(url: string, data: any): Observable<HttpEvent<{}>> {
    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });
    const req = new HttpRequest("POST", url, data, {
        reportProgress: true,
    });
    req.clone({ headers });
    return this.http.request(req);
  }


}
