import { HttpParams } from '@angular/common/http';

export interface Pagination {
  page: number ;
  size: number;
  sort: string[];
}

export interface Search {
  pid: string;
  first_name: string;
  last_name: string;
  commune:string;
}

export interface SearchWithPagination extends Search, Pagination {}

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
  
};
