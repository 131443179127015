import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user: any;
  url: any = environment.apiUrl;
  constructor(public auth: AuthService) { 
    this.user = this.auth.currentUserValue;
  }

  ngOnInit() {
  }

}
