import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  role: any = {
    name: "",
    categorie: "",
    description:"",
    privileges:[]
  };
  cats: any = [];
  privileges: any = [];
  constructor(private app:AppService ) { }

  ngOnInit(): void {
    this.getAllPrivileges();
    this.getAllCategorieRole();
  }
  reset() {
    this.role = {
      name: "",
      categorie: "",
      description:"",
      privileges:[]
    };
   }

  getAllCategorieRole() {
 this.app.getData(`${environment.apiUrl}role/getCategorieRole`).subscribe(
      (data) => {
        this.setCat(data);
      },
      (error) => {
        throw error;
      }
    );
  }

  addNewRole() {
    if (this.role.name=="" || this.role.categorie=="") { 
        this.app.toastError("Vous devez ajouter un nom et selectionner une categorie.");
    }
    if (this.role.privileges.length == 0) { 
       this.app.toastError("Vous devez ajouter au moins un privilege.");
       return;
    }
    this.app.setData(`${environment.apiUrl}role/addNewRole`, this.role).subscribe(
         (data) => {
        if (data.crash) {
          this.app.toastError(data.message); 
          return;
          }
        this.app.toastSuccess(data.message); 
        this.reset();
         },
         (error) => {
          this.app.toastError(error); 
         }
       );
     }

  setCat(data: any) {
    this.cats = data;
  }

  getAllPrivileges() {
    this.app.getData(`${environment.apiUrl}role/getAllPrivileges`).subscribe(
      (data) => {
        console.log(data);
        this.privileges = data;
      },
      (error) => {
        throw error;
      }
    );
  }

  addNewPrivToRole(role: any , i: number) { 
    const data = this.role.privileges.filter(f => f.id === role.id);
    this.privileges.splice(i, 1);
    if (data.length == 0) {
      this.role.privileges.push(role);
      } else  {
       this.app.toastError("Cet privilege existe déja.");
    }
  }

  delPrivToRole(role: any , i: number) { 
    this.privileges.push(role);
    this.role.privileges.splice(i,1);
  }

}
