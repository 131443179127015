<app-breadcrumb [title]="'TrafficMana'" [items]="['Dashboard']" [active_item]="'Ajouter role'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 row">
            <div class="col-md-6">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Nom role</label>
                        <input class="form-control" type="text" [(ngModel)]="role.name" />
                    </div>
                    <div class="form-group">
                        <label>Categorie role</label>
                        <select class="form-control" [(ngModel)]="role.categorie">
                               <option value="{{item}}"  *ngFor="let item of cats"> {{item}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" [(ngModel)]="role.description">
                        </textarea>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary mr-1" (click)=" addNewRole()">Ajouter</button>
                    </div>
                    <div class="card-footer" *ngIf="role.privileges.length>0">
                        <span class="badge badge-primary" *ngFor="let item of role.privileges; let i = index">
                            {{item.name}}  <i class="fa fa-close text-danger" (click)="delPrivToRole(item,i)"></i>
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="col-sm-12 col-xl-12 ">
                    <div class="card">
                        <div class="card-header">
                            <h5>Liste privileges disponible</h5>
                        </div>
                        <div class="card-body priv">
                            <ul class="list-group">
                                <li *ngFor="let item of privileges; let i = index" class="list-group-item d-flex justify-content-between 
                                align-items-center">{{item.name}}
                                    <span class="badge badge-primary" (click)=" addNewPrivToRole(item , i)">
                                    <i class="fa fa-plus"></i>
                                </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>